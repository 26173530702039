import {combineReducers} from 'redux'

import DashboardReducer from './dashboard'

const storeList = {
  dashboard: DashboardReducer
}
/**
 * 각 업무에서 사용하는 Reducer 병합 처리
 */
const storeReducer = combineReducers(storeList)

const reducer = combineReducers(
  {
    blackReducer: storeReducer,
  }
)
export type ReducerType = ReturnType<typeof reducer>;
export default reducer;