import React, {useEffect} from 'react'
import {RouterProvider} from 'react-router-dom'
import Routes from './router/routes'
import styled from 'styled-components'

function App() {
  const setMobileHeight = () => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
  useEffect(() => {
    setMobileHeight();

    // resize 이벤트가 발생하면 다시 계산하도록 아래 코드 추가
    window.addEventListener('resize', setMobileHeight);
    return () => window.removeEventListener('resize', setMobileHeight);
  }, []);


  return (
    <Main className="App">
      <RouterProvider router={Routes}/>
    </Main>
  );
}

export default App;

const Main = styled.main`
  background-color: black;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
`