import styled from 'styled-components'
import React, {useEffect, useState} from 'react'
import ReactPlayer from 'react-player';
import moment from 'moment/moment'
import {useTimer} from 'react-timer-hook'
import {useLocation, useNavigate} from 'react-router-dom'

const PlayerComponent = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const FirstVideo = "https://customer-8algng3epz9dw43u.cloudflarestream.com/0de860a37a2a2df8ef4411f4a6c76780/manifest/video.m3u8"
  const SecondVideo = "https://customer-8algng3epz9dw43u.cloudflarestream.com/7cc7d45aa541f4ed1dcdf3c1b1bc476d/manifest/video.m3u8"

  const time1 = moment('2023-10-28T00:00:00+09:00').utc().toDate()
  const {
    totalSeconds,
    isRunning
  } = useTimer({
    expiryTimestamp: time1,
    autoStart: true
  })

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [imgList] = useState([
    FirstVideo,
    SecondVideo
  ])

  useEffect(() => {
    if (totalSeconds > 0) {
      setCurrentImageIndex(0)
    } else {
      setCurrentImageIndex(1)
    }
  }, [isRunning])

  useEffect(() => {
    if (!location?.state?.answer) {
      navigate('/')
    }
  }, [])

  const currentImage = imgList[currentImageIndex];

  return (
    <VideoWrapper>
      <ReactPlayer
        url={currentImage}
        width={'100%'}
        height={'calc(100% - 20px)'}
        playsInline
        muted
        controls
        playing
        // onEnded={onEndedHandler}
        config={{
          file: {
            attributes: {
              controlsList: 'noplaybackrate nodownload',
              disablepictureinpicture: 'true'
            }
          }
        }}
      />
    </VideoWrapper>
  )
}

export default PlayerComponent

const VideoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
`