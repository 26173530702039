import {RouteObject} from 'react-router'
import {createBrowserRouter, Navigate} from 'react-router-dom'
import React from 'react'
import HomeViewer from '../pages/home'
import PlayerViewer from '../pages/player'

export type NewRouteObject = RouteObject & {
  permission?: string
  children?: {
    permission?: string
  }
}

const routes: NewRouteObject[] = []

const homeLayout: NewRouteObject = {
  element: <HomeViewer />,
  path: '/'
}
const playerLayout: NewRouteObject = {
  element: <PlayerViewer />,
  path: '/player',
  // children: [
  //   { path: `/player/:playId`, element: null },
  // ],
}
const mainLayout: NewRouteObject = {
  element: <Navigate to="/" />,
  path: '*'
}

routes.push(mainLayout)
routes.push(homeLayout)
routes.push(playerLayout)

const router = createBrowserRouter(routes)

export default router

// function PlayerWrapper() {
//   if () {
//     return <PlayerViewer />;
//   } else {
//     return <Navigate to="/home" />;
//   }
// }