import React, {useState} from 'react'
import styled from 'styled-components'
import moment from 'moment'
import {useTimer} from 'react-timer-hook'
import {useNavigate} from 'react-router-dom'

const HomeViewer = () => {
  const navigate = useNavigate()
  const answer = 'popoff'
  const answerImage2 = "https://imagedelivery.net/ccvaaeRfK4QkKsPSngfyFg/31cc7608-81ef-4ac4-5fde-680897bc0800/public"
  const title = "https://imagedelivery.net/ccvaaeRfK4QkKsPSngfyFg/bad0dbf5-ee83-45e6-b334-7c273ac07c00/public"

  const time1 = moment('2023-11-02T18:00:00+09:00').utc().toDate()
  const {
    totalSeconds,
    seconds,
    minutes,
    hours,
    days
  } = useTimer({
    expiryTimestamp: time1,
    autoStart: true
  })
  const [inputAnswer, setInputAnswer] = useState('');

  const onChangeAnswer = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputAnswer(event.target.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      navigatePlayer()
    }
  };
  const onClickAnswer = () => {
    navigatePlayer()
  }

  const navigatePlayer = () => {
    if (inputAnswer?.toLowerCase() === answer) {
      navigate('/player', {state: { answer: true}});
    } else {
      console.log('틀렸습니다.')
    }
  }

  return (
    <Main style={{}}>
      <HomeWrapper>
        <Title>
          <TitleImg alt={"정답이미지"} src={title}/>
        </Title>
        <MainImg alt={"정답이미지"} src={answerImage2}>
        </MainImg>
        <AnswerInputWrapper>
          <AnswerInput
            type="text"
            className="underline-input"
            onChange={onChangeAnswer}
            onKeyDown={handleKeyPress}
          />
          <Arrow className="arrow" onClick={onClickAnswer}>&#10132;</Arrow>
        </AnswerInputWrapper>
        { totalSeconds > 0 ?
          <TimerDiv>
            {`${days < 10 ? "0" + days : days}: ${hours < 10 ? "0" + hours : hours}: ${minutes < 10 ? "0" + minutes : minutes}: ${seconds < 10 ? "0" + seconds : seconds}`}</TimerDiv>
          :
          <></>
        }
      </HomeWrapper>
    </Main>
  )
}

export default HomeViewer

const Main = styled.div`
  background-image: url("https://imagedelivery.net/ccvaaeRfK4QkKsPSngfyFg/a07187a2-2502-401c-1c01-89b737937a00/public");
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-size: 100% 100%; /* 이미지를 꽉 차게 표시합니다 */
  background-repeat: no-repeat; /* 이미지 반복 없이 표시합니다 */
  background-position: center; /* 이미지를 가운데 정렬합니다 */
  @media screen and (max-width: 767px) {
    background-image: url("https://imagedelivery.net/ccvaaeRfK4QkKsPSngfyFg/46af423c-db77-4d60-fcd6-3b1aa7f77600/public");
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    background-image: url("https://imagedelivery.net/ccvaaeRfK4QkKsPSngfyFg/0c9c42e8-591c-47d7-b044-d624ae0d0c00/public");
  }
`

const HomeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`
const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 17.18%;
  width: 25.52%;
  color: white;
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    height: 15.00%;
    width: 49.51%;
  }
  @media screen and (max-width: 767px) {
    height: 13.15%;
    width: 65.13%;
  }
`
const MainImg = styled.img`
  padding-top: 1.12vh;
  height: 61.35%;
  width: 31.88%;
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    height: 53.81%;
    width: 61.95%;
  }
  @media screen and (max-width: 767px) {
    height: 45.85%;
    width: 81.54%;
  }
`
const TitleImg = styled.img`
  height: 100%;
  width: 100%;
`
const AnswerInputWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5.58%;
  width: 15.59%;
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    height: 4.83%;
    width: 32.56%;
  }
  @media screen and (max-width: 767px) {
    height: 4.27%;
    width: 42.82%;
  }
`
const AnswerInput = styled.input`
  border: none;
  border-bottom: 4px solid white; /* 밑줄 스타일 */
  width: 100%;
  padding-right: 30px; /* 우측 화살표와 겹치지 않도록 여백 추가 */
  background-color: transparent;
  color: white;
`
const Arrow = styled.span`
  position: absolute;
  right: 3px; /* 우측 여백 조절 */
  top: 50%; /* 수직 가운데 정렬을 위해 */
  transform: translateY(-50%);
  cursor: pointer; /* 화살표에 커서 포인터 스타일 적용 */
  color: white;
`
const TimerDiv = styled.div`
  font-family: "DS-DIGI";
  position: fixed;
  text-align: center;
  transform: translate(0%, -50%);
  color: white;
  font-size: 3vw;
  width: 16.78%;
  height: 5.58%;
  @media screen and (min-width: 1024.1px) {
    left: 74.58%;
    top: 50%;
  }
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    top: 91%;
    width: 100%;
    height: 4.89%;
    font-size: 6.4vw;
  }
  @media screen and (max-width: 767px) {
    top: 87.5%;
    font-size: 8vw;
    width: 100%;
    height: 4.28%;
  }
;
`