import {createSlice} from '@reduxjs/toolkit'
import type { PayloadAction } from "@reduxjs/toolkit"
import {DashBoard, DashBoardState, MouseMode, Values} from './type'

const initialState: DashBoardState = {
  // 대시보드 파일 데이터
  dashBoard: [],
  // 대시보드 파일 데이터
  dashBoardContents: {},
  // 대시보드 히스토리
  dashboardHistory: [],
  // 다시보드 값 데이터
  values: [],
  // 대시보드 심볼 파일 데이터
  symbol: {},
  // 로딩바 표시 여부
  isLoading: false,
  // 마우스 커서 모드
  mouseMode: 'select',
  // 드래그 시 선택된 태그 이름 목록
  selectTagNameList: [],
  // 대시보드(해당 boardId) 전체 태그 이름 목록
  dashboardTagNameList: [],
  // 라이브 모드 여부
  liveMode: true,
  // 재생 모드 여부
  playMode: true,
  // 과거 모드 시작일자
  startDt: 0,
  // 과거 모드 종료일자
  endDt: 0,
  // 현재 재생 일자
  currentDt: 0
};

export const dashBoardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    // 대시보드 api 결과 저장 ( /api/Dashboard/info/${boardId} )
    setDashboardData(
      state: DashBoardState,
      action: PayloadAction<DashBoard[]>): void {
      state.dashBoard = action.payload
    },
    // 대시보드 값 api 결과 저장 ( /api/Dashboard/value/realtime )
    setDashboardValue(
      state: DashBoardState,
      action: PayloadAction<Values[]>): void {
      state.values = action.payload
    },
    // 심볼 api 결과 저장 ( /api/Dashboard/symbol/info/list )
    setSymbolData(
      state: DashBoardState,
      action: PayloadAction<any>): void {
      state.symbol = action.payload
    },
    // 대시보드 조회 히스토리 적재
    addDashboardHistory(
      state: DashBoardState,
      action: PayloadAction<string>): void {
      const checkDuplicate = state.dashboardHistory.find((item) => {
        return item === action.payload
      })
      if (checkDuplicate) {
        const uniqueArr = state.dashboardHistory.filter((item) => item !== action.payload)
        state.dashboardHistory = [...uniqueArr, action.payload]
      } else {
        state.dashboardHistory = [...state.dashboardHistory, action.payload]
      }
    },
    // 마우스 모드 설정
    setMouseMode(
      state: DashBoardState,
      action: PayloadAction<MouseMode>): void {
      state.mouseMode = action.payload
    },
    // 리플레이 모드 시작일자 설정
    setStartDt(
      state: DashBoardState,
      action: PayloadAction<number>): void {
      state.startDt = action.payload
    },
    // 리플레이 모드 종료일자 설정
    setEndDt(
      state: DashBoardState,
      action: PayloadAction<number>): void {
      state.endDt = action.payload
    },
    // 리플레이 모드 현재일자 설정
    setCurrentDt(
      state: DashBoardState,
      action: PayloadAction<number>): void {
      state.currentDt = action.payload
    },
    // 리플레이모드 현재일자 가감
    addCurrentDt(
      state: DashBoardState,
      action: PayloadAction<number>): void {
      state.currentDt = state.currentDt + action.payload
    },
    // 로딩바 on
    onLoading(
      state: DashBoardState): void {
      state.isLoading = true
    },
    // 로딩바 off
    offLoading(
      state: DashBoardState): void {
      state.isLoading = false
    },
    // 대시보드 선택된 태그 이름 리스트 추가
    addSelectTagNameList(
      state: DashBoardState,
      action: PayloadAction<string>): void {
      const tagNameList = action.payload.split(',')
      for (let i = 0; i < tagNameList.length; i++) {
        const checkDuplicate = state.selectTagNameList.find((item) => {
          return item === tagNameList[i]
        })
        if (checkDuplicate) {
          const uniqueArr = state.selectTagNameList.filter((item) => item !== tagNameList[i])
          state.selectTagNameList = [...uniqueArr, tagNameList[i]]
        } else {
          state.selectTagNameList = [...state.selectTagNameList, tagNameList[i]]
        }
      }
    },
    // 대시보드 선택된 태그 리스트 초기화
    resetSelectTagNameList(
      state: DashBoardState): void {
      state.selectTagNameList = []
    },
    // 대시보드 선택된 태그 리스트 설정
    setDashboardTagNameList(
      state: DashBoardState,
      action: PayloadAction<string[]>): void {
      state.dashboardTagNameList = action.payload
    },
    // 대시보드 태그 리스트 초기화
    resetDashboardTagNameList(
      state: DashBoardState): void {
      state.dashboardTagNameList = []
    },
    // 라이브 모드 설정
    setLiveMode(
      state: DashBoardState): void {
      state.liveMode = !state.liveMode
    },
    // 리플레이 재생 모드 설정
    setPlayMode(
      state: DashBoardState): void {
      state.playMode = !state.playMode
    },
    // 대시보드 XML 파싱 내용 설정
    setDashBoardContents(
      state: DashBoardState,
      action: PayloadAction<{}>): void {
      state.dashBoardContents = action.payload
    },
  },
});

export const {
  setDashboardData,
  setDashboardValue,
  onLoading,
  offLoading,
  setSymbolData,
  addDashboardHistory,
  setMouseMode,
  addSelectTagNameList,
  resetSelectTagNameList,
  setLiveMode,
  setPlayMode,
  setDashBoardContents,
  setStartDt,
  setEndDt,
  addCurrentDt,
  setCurrentDt,
  setDashboardTagNameList,
  resetDashboardTagNameList
} = dashBoardSlice.actions;
export default dashBoardSlice.reducer;
